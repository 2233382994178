<template>
    <div>
        <div class="tab">
            <el-tabs v-model="index_code" @tab-click="tabClickHandle" style="float:left;margin-top:10px;width:98.4%;position:relative;">
                <el-tab-pane name="000001">
                    <span slot="label" style="margin-left:16px">{{shang}}
                        <el-tooltip placement="bottom">
                            <div slot="content">上证指数是以在上海证券交易所上市的全部股票（包括A股和B股）为样本股，以总股<br/>本为权数的加权综合股价指数，反映了上海证券交易所上市股票价格的变动情况。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="shang" ref="shang" v-if="'000001'==index_code"/>
                </el-tab-pane>

                <el-tab-pane name="399001">
                    <span slot="label">{{shen}}
                        <el-tooltip content="" placement="bottom">
                            <div slot="content">深证成指以在深交所上市的具有市场代表性的500家公司的股票为样本股，以自由流通股<br/>为权数，采用派式加权法编制，充分反映了深圳市场的运行特征。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="shen" ref="shang" v-if="'399001'==index_code"/>
                </el-tab-pane>

                <el-tab-pane name="399006">
                    <span slot="label">{{chuang}}
                        <el-tooltip  placement="bottom">
                            <div slot="content">创业板指从创业板股票中选取100只组成样本股，以自由流通股为权数，采用派式加权法<br/>编制，全面客观地反映创业板股票的总体价格变动和走势。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="chuang" ref="shang" v-if="'399006'==index_code"/>
                </el-tab-pane>

                <el-tab-pane name="000300">
                    <span slot="label">{{hu}}
                        <el-tooltip placement="bottom">
                            <div slot="content">沪深300指数选取沪深两个市场中市值大、流动性高、交易活跃的300只股票为样本股，以自由流通量为权数，采用派式加权法、分级<br/>靠档技术和缓冲区规则编制，客观真实地反映A股市场运行状况，为市场提供了一个投资标尺。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="hu" ref="shang" v-if="'000300'==index_code"/>
                </el-tab-pane>
            </el-tabs>
            <span style="position:absolute;left:73%;height:50px;line-height:50px;">预测方法： 
                <el-radio-group v-model="method" @change="radioHangeHandler">
                    <el-radio label="garch_skewt">GARCH法</el-radio>
                    <el-radio label="ewma_hs">EWMA法</el-radio>
                </el-radio-group>                    
            </span>
        </div>
    </div>
</template>

<script>
import Shang from './shang'
export default {
    name: 'hushen',
    data () {
        return {
            index_code: '000001',
            method: 'garch_skewt',
            length: '1y',
            down_color: '#67B35A',
            up_color: '#E64545', 
            shang:'上证指数' ,
            shen:'深证指数' ,
            chuang:'创业板指' ,
            hu:'沪深300',
            isHushenFont: true                  
        }
    },
    components:{
        Shang
    },  
    mounted(){
        this.$nextTick(() => {
            this.initEcharts()
        })
    },        
    methods: { 
        // changeData:function(){
        //     let params = {
        //         "index_code": this.index_code,
        //         "length": this.length,
        //         "method": this.method,
        //         "down_color": this.down_color,
        //         "up_color":this.up_color
        //     };     
        //     this.$refs.varEsEcharts.changeData(params) ;
        //     this.$refs.realizedVolEcharts.changeData(params) ;
        // },
        initEcharts: function () {
            //调用子组件的函数，并把这个状态传过去。
            //this.$refs.shang.changeData()
        },             
        radioHangeHandler: function (value) {
            this.method = value
            //调用子组件的函数，并把这个状态传过去。
            this.$refs.shang.changeData()
        }, 
        tabClickHandle: function (tab) {
            this.index_code = tab.name
            //调用子组件的函数，并把这个状态传过去。
        }
    }
}
</script>
<style lang='less'>
.el-tabs__nav-wrap::after {
    content: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    z-index: 1;
}
.el-tabs__item.is-active, .el-tabs__item:hover {
    color: #2E5082;
}
.el-radio__input.is-checked .el-radio__inner {
    border-color: #5F80A9;
    background: #5F80A9;
}
.el-radio__input.is-checked+.el-radio__label {
    color: #5F80A9;
}
.el-tabs__active-bar {
    background-color: #2E5082;
}
</style>
<style lang='less' scoped>
.tab{
    width:116.5%;
    height:50px;
    // padding:0 16px;
    background:#F7F7F7;
    position:relative;
    border-top:solid 1px #ddd;
}
</style>